export const useDashboardItemStore = defineStore('dashboardItem', {
  state: () => ({
    geoEntities: [],
    nominatimAddresses: [],
    nominatimCountries: [],
    detailSelectedTab: 'General',
    detailsKeys: [],
    detailTabs: [
      { name: 'General', count: false },
      { name: 'Content', count: false },
      { name: 'Media', count: false },
      { name: 'Address', count: false },
      { name: 'Stats', count: false },
    ],
    resultPage: 1,
    resultSearchText: '',
    resultSelectedTags: [],
    resultShowDeletedItems: false,
    resultShowFilterMenu: false,
    resultShowHasSuccessor: false,
    resultShowIsHiddenItems: false,
    resultShowItemsWithNoAddress: false,
    resultShowPermanentlyClosedItems: false,
    resultTagsComboboxQuery: '',
    userCache: [],
  }),
  actions: {
    async fetchDetailKeys() {
      const supabase = useSupabaseClient()
      const { data } = await supabase
        .from('details_keys')
        .select(
          'id, key, detailType:detail_types_id(name), iconId:icon_id, iconType:icon_type, color, bgColor:bg_color'
        )
      console.log('store:dashboardItem: fetchDetailKeys: data: ', data.length)
      if (data) {
        this.detailsKeys = data
      }
    },
    async fetchGeoEntities() {
      const supabase = useSupabaseClient()
      const { data } = await supabase.from('geo_entities').select('*')
      console.log('store:dashboardItem: fetchGeoEntities: data: ', data.length)
      if (data) {
        this.geoEntities = data
      }
    },
    async fetchNominatimPlace(itemIdMayBeRef, latMayBeRef, lonMayBeRef) {
      const itemId = unref(itemIdMayBeRef)
      const lat = unref(latMayBeRef)
      const lon = unref(lonMayBeRef)
      if (!itemId) return 'Error: no itemId'
      // reverse lookup for osm_id
      const reverseUrl = `https://nominatim.openstreetmap.org/reverse.php?lat=${lat}&lon=${lon}&zoom=18&format=jsonv2`
      console.log(
        'store:dashboardItem:fetchNominatimPlace: reverseUrl: ',
        reverseUrl
      )
      const { data: reverseData, error: reverseError } =
        await useFetch(reverseUrl)
      if (reverseError.value) {
        console.log(
          'store:dashboardItem:fetchNominatimPlace: reverseError: ',
          reverseError.value
        )
        return
      }
      if (reverseData.value) {
        console.log(
          'store:dashboardItem:fetchNominatimPlace: reverseData: ',
          reverseData.value
        )

        const osmId = reverseData.value.osm_id
        const osmType = reverseData.value.osm_type
        if (!osmType) return 'Error: no osm_type'
        const osmTypeShort = osmType.substring(0, 1).toUpperCase()

        const { data: detailData, error: detailError } = await useFetch(
          'https://nominatim.openstreetmap.org/details.php?osmtype=' +
            osmTypeShort +
            '&osmid=' +
            osmId +
            '&addressdetails=1&hierarchy=0&group_hierarchy=1&format=json'
        )
        if (detailError.value) {
          console.log(
            'store:dashboardItem:fetchNominatimPlace: detailError: ',
            detailError.value
          )
          return
        }
        if (detailData.value) {
          console.log(
            'store:dashboardItem:fetchNominatimPlace: detailData: ',
            detailData.value
          )
          this.nominatimAddresses = detailData.value

          const client = useSupabaseClient()
          const user = useSupabaseUser()
          if (!user.value) return

          const addresses = detailData.value.address
          if (addresses && addresses.length > 0) {
            // remove all existing entries for this item
            const { error: deleteError } = await client
              .from('nominatim_addresses')
              .delete()
              .eq('item_id', itemId)
            if (deleteError) {
              return 'Error deleting current nominatim_addresses'
            }
            for (const address of addresses) {
              // add address
              const newAddress = {
                item_id: itemId,
                osm_id: address.osm_id,
                osm_type: address.osm_type,
                class: address.class,
                type: address.type,
                admin_level: address.admin_level,
                isaddress: address.isaddress,
                localname: address.localname,
                place_type: address.place_type,
                inserted_by: user.value.id,
                updated_by: user.value.id,
              }
              const { data: insertData, error: insertError } = await client
                .from('nominatim_addresses')
                .insert(newAddress)
                .select()
              if (insertError) {
                console.log(
                  'store:dashboardItem:fetchNominatimPlace: insertError: ',
                  insertError
                )
                return 'Error inserting new nominatim_addresses'
              }
              if (insertData && insertData.length === 1) {
                address.id = insertData[0].id
              }
            }
            console.log(
              'store:dashboardItem:fetchNominatimPlace: before getAddress'
            )
            const { getAddress } = useAddressRecipe()
            const newAddress = getAddress(itemId, addresses, 16)
            console.log(
              'store:dashboardItem:fetchNominatimPlace: getAddress: newAddress: ',
              newAddress
            )
            if (Array.isArray(newAddress)) {
              for (const address of newAddress) {
                if (address.type === 'country') {
                  const entity = this.getCountryByIso1(address.country_code)
                  console.log(
                    'store:dashboardItem:fetchNominatimPlace:country:entity: ',
                    entity
                  )
                  if (entity && entity.id) {
                    address.geo_entity_id = entity.id
                    address.value = entity.name_en
                  }
                }
                if (address.type === 'state' || address.type === 'city') {
                  if (!address.osm_id) return 'Error: no osm_id'
                  if (!address.osm_type) return 'Error: no osm_type'
                  const entity = await this.fetchOrGetGeoEntityByOsm(
                    address.osm_id,
                    address.osm_type
                  )
                  console.log(
                    'store:dashboardItem:fetchNominatimPlace:stateOrCity:entity: ',
                    entity
                  )
                  if (entity && entity.id) {
                    address.geo_entity_id = entity.id
                  }
                }
                // address ready to insert
                console.log(
                  'store:dashboardItem:fetchNominatimPlace: address: ',
                  address
                )
                // check if there is already an entry for this item and type
                const { data: addressData, error: addressError } = await client
                  .from('items_addresses')
                  .select()
                  .eq('item_id', itemId)
                  .eq('type', address.type)
                if (addressError) {
                  console.log(
                    'store:dashboardItem:fetchNominatimPlace: addressError: ',
                    addressError
                  )
                  return 'Error selecting items_addresses'
                }
                console.log(
                  'store:dashboardItem:fetchNominatimPlace: addressData: ',
                  addressData
                )
                if (addressData && addressData.length === 0) {
                  // insert new entry
                  const { error: insertError } = await client
                    .from('items_addresses')
                    .insert({
                      item_id: itemId,
                      geo_entity_id: address.geo_entity_id,
                      type: address.type,
                      value: address.value,
                      nominatim_address_id: address.nominatim_address_id,
                      inserted_by: user.value.id,
                      updated_by: user.value.id,
                    })
                  if (insertError) {
                    console.log(
                      'store:dashboardItem:fetchNominatimPlace: insertError: ',
                      insertError
                    )
                    return 'Error inserting new items_addresses'
                  }
                }
              }
            }
          }
        }
      }
    },
    async fetchOrGetGeoEntityByOsm(osmIdMayBeRef, osmTypeMayBeRef) {
      const osmId = unref(osmIdMayBeRef)
      const osmType = unref(osmTypeMayBeRef)
      if (!osmId) return 'Error: no osmId'
      if (!osmType) return 'Error: no osmType'
      const osmTypeShort = osmType.substring(0, 1).toUpperCase()
      // Look up in store
      const geoEntityInStore = this.geoEntities.find(
        (geoEntity) =>
          geoEntity.osm_id === osmId && geoEntity.osm_type === osmTypeShort
      )
      if (geoEntityInStore) {
        return geoEntityInStore
      }
      console.log(
        'store:dashboardItem:fetchOrGetGeoEntityByOsm:lookupInStor: not in store'
      )
      // Look up in db
      const client = useSupabaseClient()
      const { data, error } = await client
        .from('geo_entities')
        .select()
        .eq('osm_id', osmId)
        .eq('osm_type', osmTypeShort)
      if (error) {
        console.log(
          'store:dashboardItem:fetchOrGetGeoEntityByOsm: error: ',
          error
        )
        return 'Error fetching geo_entity'
      }
      console.log(
        'store:dashboardItem:fetchOrGetGeoEntityByOsm:lookupInDb:data: ',
        data
      )
      if (data && data.length > 0) {
        this.geoEntities.push(data[0])
        return data
      }
      // Fetch from nominatim
      const fetchedEntity = await this.fetchOsmEntity(osmId, osmType)
      console.log(
        'store:dashboardItem:fetchOrGetGeoEntityByOsm: fetchedEntity: ',
        fetchedEntity
      )
      if (fetchedEntity) {
        return fetchedEntity
      }
    },
    async fetchOsmCountries() {
      const user = useSupabaseUser()
      if (!user.value) return
      const { data, error } = await useFetch(
        'https://overpass-api.de/api/interpreter?data=%5Bout%3Ajson%5D%3B%20relation%5B%22admin_level%22%3D%222%22%5D%5B%22ISO3166-1%22~%22%5E..%24%22%5D%5B%22type%22%3D%22boundary%22%5D%3Bout%20tags%3B'
      )
      if (error.value) {
        console.log(
          'store:dashboardItem:fetchOsmCountries: error: ',
          error.value
        )
        return
      }
      if (data.value) {
        const newCountries = []
        for (const country of data.value.elements) {
          const newCountry = {}
          newCountry.osm_id = country.id
          newCountry.osm_type = country.type.substring(0, 1).toUpperCase()
          newCountry.name = country.tags.name
          newCountry.inserted_by = user.value.id
          newCountry.updated_by = user.value.id
          if (country.tags.int_name) {
            newCountry.int_name = country.tags.int_name
          }
          if (country.tags['name:en']) {
            newCountry.name_en = country.tags['name:en']
          }
          if (
            country.tags['ISO3166-1:alpha2'] &&
            country.tags['ISO3166-1:alpha2'].length > 0
          ) {
            newCountry.iso_3166_1_alpha_2 = country.tags['ISO3166-1:alpha2']
          } else if (
            country.tags['ISO3166-1'] &&
            country.tags['ISO3166-1'].length === 2
          ) {
            newCountry.iso_3166_1_alpha_2 = country.tags['ISO3166-1']
          }

          newCountry.iso_3166_1_alpha_3 = country.tags['ISO3166-1:alpha3']
          newCountry.admin_level = country.tags.admin_level

          newCountries.push(newCountry)
        }
        this.nominatimCountries = newCountries

        const client = useSupabaseClient()

        if (this.nominatimCountries.length > 0) {
          for (const country of this.nominatimCountries) {
            // check if country is already in db
            const { data: dataEntities, error: errorEntities } = await client
              .from('geo_entities')
              .select()
              .eq('osm_id', country.osm_id)
              .eq('osm_type', country.osm_type)
            console.log(
              'store:dashboardItem:fetchOsmCountries: dataEntities: ',
              dataEntities
            )
            if (errorEntities) {
              console.log(
                'store:dashboardItem:fetchOsmCountries: error: ',
                errorEntities
              )
            }
            if (dataEntities.length === 0) {
              // insert country
              await client.from('geo_entities').insert(country)
            }
          }
        }
      }
    },
    async fetchOsmEntity(osmIdMayBeRef, osmTypeMayBeRef) {
      const osmId = unref(osmIdMayBeRef)
      const osmType = unref(osmTypeMayBeRef)
      if (!osmId) return 'Error: no osmId'
      if (!osmType) return 'Error: no osmType'
      const user = useSupabaseUser()
      const osmTypeShort = osmType.substring(0, 1).toUpperCase()
      let fetchUrl = ''
      if (osmTypeShort === 'R') {
        fetchUrl =
          'https://overpass-api.de/api/interpreter?data=%5Bout%3Ajson%5D%3Brelation%28' +
          osmId +
          '%29%3Bout%20tags%3B'
      } else if (osmTypeShort === 'W') {
        fetchUrl =
          'https://overpass-api.de/api/interpreter?data=%5Bout%3Ajson%5D%3Bway%28' +
          osmId +
          '%29%3Bout%20tags%3B'
      } else if (osmTypeShort === 'N') {
        fetchUrl =
          'https://overpass-api.de/api/interpreter?data=%5Bout%3Ajson%5D%3Bnode%28' +
          osmId +
          '%29%3Bout%20tags%3B'
      }
      console.log('store:dashboardItem:fetchOsmEntity: fetchUrl: ', fetchUrl)
      const { data, error } = await useFetch(fetchUrl)
      if (error.value) {
        console.log('store:dashboardItem:fetchOsmEntity: error: ', error.value)
        return 'Error fetching osm entity'
      }
      console.log('store:dashboardItem:fetchOsmEntity: data: ', data)
      if (data.value && data.value.elements && data.value.elements.length > 0) {
        const osmEntity = data.value.elements[0]
        const newEntity = {}

        console.log(
          'store:dashboardItem:fetchOsmEntity: osmEntity: ',
          osmEntity
        )

        newEntity.osm_id = osmEntity.id
        newEntity.osm_type = osmEntity.type.substring(0, 1).toUpperCase()
        newEntity.inserted_by = user.value.id
        newEntity.updated_by = user.value.id
        if (osmEntity.tags.name) {
          newEntity.name = osmEntity.tags.name
        }
        if (osmEntity.tags.int_name) {
          newEntity.int_name = osmEntity.tags.int_name
        }
        if (osmEntity.tags['name:en']) {
          newEntity.name_en = osmEntity.tags['name:en']
        }
        if (
          osmEntity.tags['ISO3166-1:alpha2'] &&
          osmEntity.tags['ISO3166-1:alpha2'].length > 0
        ) {
          newEntity.iso_3166_1_alpha_2 = osmEntity.tags['ISO3166-1:alpha2']
        } else if (
          osmEntity.tags['ISO3166-1'] &&
          osmEntity.tags['ISO3166-1'].length === 2
        ) {
          newEntity.iso_3166_1_alpha_2 = osmEntity.tags['ISO3166-1']
        }

        if (
          osmEntity.tags['ISO3166-1:alpha3'] &&
          osmEntity.tags['ISO3166-1:alpha3'].length === 3
        ) {
          newEntity.iso_3166_1_alpha_3 = osmEntity.tags['ISO3166-1:alpha3']
        }
        if (osmEntity.tags['ISO3166-2']) {
          newEntity.iso_3166_2 = osmEntity.tags['ISO3166-2']
        }
        if (osmEntity.tags.admin_level) {
          newEntity.admin_level = osmEntity.tags.admin_level
        }
        console.log(
          'store:dashboardItem:fetchOsmEntity: newEntity: ',
          newEntity
        )
        const client = useSupabaseClient()

        const { data: dataEntities, error: errorEntities } = await client
          .from('geo_entities')
          .select()
          .eq('osm_id', newEntity.osm_id)
          .eq('osm_type', newEntity.osm_type)
        console.log(
          'store:dashboardItem:fetchOsmEntity: dataEntities: ',
          dataEntities
        )
        if (errorEntities) {
          console.log(
            'store:dashboardItem:fetchOsmEntity: error: ',
            errorEntities
          )
        }
        if (dataEntities.length === 0) {
          // insert country
          const { data: dataNewEntity, error: errorNewEntity } = await client
            .from('geo_entities')
            .insert(newEntity)
            .select()
            .single()

          // check for errors
          if (errorNewEntity) {
            console.log(
              'store:dashboardItem:fetchOsmEntity: error: ',
              errorNewEntity
            )
            return 'Error inserting new entity'
          }
          console.log(
            'store:dashboardItem:fetchOsmEntity: dataNewEntity: ',
            dataNewEntity
          )

          // receive back & save to store
          this.geoEntities.push(dataNewEntity)

          // return new entity (for id)
          return dataNewEntity
        }
      } else {
        return 'Error: no data'
      }
    },
    getCountryByIso1(isoCodeMayBeRef) {
      const isoCode = unref(isoCodeMayBeRef)
      if (!isoCode) return 'Error: no isoCode'
      const country = this.geoEntities.find(
        (entity) => entity.iso_3166_1_alpha_2 === isoCode.toUpperCase()
      )
      return country
    },
    getDetailKeyByKey(keyMayBeRef) {
      const key = unref(keyMayBeRef)
      if (!key) return 'Error: no key'
      const detailKey = this.detailsKeys.find(
        (detailKey) => detailKey.key === key
      )
      return detailKey
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useDashboardItemStore, import.meta.hot)
  )
}
